import { useMemo, useState } from 'react';
import moment from 'moment';
import {
  AgxColumn,
  AgxHeader,
  Images,
  AgxBodyText,
  AgxButton,
  AgxSlideUpModal,
  FormType,
  CampaignStage,
  useWindowSize,
  Breakpoints,
  CampaignDetailModel,
} from '@urbanx/agx-ui-components';
import { isEqual } from 'lodash';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { cleanTwoLineAddress } from 'utils/formatAddress';
import { Tabs } from 'types/Tabs';
import { setAndShowErrorToast } from 'store/config';
import useStateFormLabels from '../../../../../form/FormLabels/useStateFormLabels';
import FormDetailsHeader from './FormDetailsHeader';
import { completeCampaignStep } from 'Api/Campaigns/campaignsApi';
import { useFormLoader } from 'hooks/useFormLoader';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { getEnumValue } from 'helpers/enumHelper';

const ResumeFormDetails = ({
  campaign,
  currentTab,
  mobile = false,
}: {
  campaign: CampaignDetailModel;
  currentTab: string;
  mobile?: boolean;
}) => {
  const { inProgressForms, address, generatedDocuments, campaignId, stage } =
    campaign;

  const disabledStages = [
    CampaignStage.AgreementDraft,
    CampaignStage.AgreementAgentSigning,
    CampaignStage.AgreementVendorSigning,
  ];

  const availableForms = useAppSelector(state => state.forms.availableForms);

  const dispatch = useAppDispatch();
  const [, getAuthToken] = useAzureAuth();
  const { loadForm, loadFormWithConfig } = useFormLoader();

  const [showConfirmationModel, setShowConfirmationModel] = useState(false);
  const [disableButtons, setDisableButtons] = useState(() =>
    disabledStages.includes(stage)
  );

  const screenSize = useWindowSize();
  const isMobile = screenSize === Breakpoints.Mobile;

  const formName = useStateFormLabels(campaign.state, 'formName');

  const isHaveGeneratedDocs = generatedDocuments?.length > 0;

  const inProgressForm = useMemo(() => {
    if (inProgressForms == null || !inProgressForms.some(ipf => ipf != null))
      return null;
    switch (getEnumValue(CampaignStage, campaign.stage)) {
      case CampaignStage.AgreementSubmissionIncomplete:
        return inProgressForms.find(
          ipf => ipf.formType === FormType.ListingSubmission
        );
      default:
        return inProgressForms.find(
          ipf => ipf.formType === FormType.ListingGenerator
        );
    }
  }, [inProgressForms, generatedDocuments]);

  if (!inProgressForm) {
    return <>No in progress forms found</>;
  }

  if (!formName || !availableForms) return;

  const { formId, lastUpdate } = inProgressForm;

  const lastUpdatedOn = `Last updated ${moment(lastUpdate).format(
    'DD/MM/YYYY'
  )}`;

  const [addressLineOne, addressLineTwo] = cleanTwoLineAddress(address);

  const resumeAgencyAgreement = async () => {
    try {
      setDisableButtons(true);

      await loadForm(campaignId, formId);
    } catch (err) {
      console.error(err);
      dispatch(setAndShowErrorToast('Failed to resume Agency Agreement'));
    }

    setDisableButtons(false);
  };

  const beginAgencyAgreementSubmission = async () => {
    const agencyAgreementSubmissionForm = availableForms.find(
      form =>
        form.type === FormType.ListingSubmission &&
        isEqual(form.state, campaign.state)
    );

    if (agencyAgreementSubmissionForm == null) {
      return;
    }

    try {
      setDisableButtons(true);

      const authToken = await getAuthToken();
      if (!authToken) return;

      const newForm = await completeCampaignStep(
        authToken,
        campaignId,
        true,
        inProgressForm?.formId
      );

      if (!newForm) {
        dispatch(
          setAndShowErrorToast('Failed to start Agency Agreement submission')
        );
        setDisableButtons(false);
        return;
      }

      await loadFormWithConfig(newForm);
    } catch (err) {
      dispatch(
        setAndShowErrorToast('Failed to start Agency Agreement submission')
      );
    }

    setDisableButtons(false);
  };

  const confirmationDialogBody = (
    <AgxColumn veryLargeGap extraClasses={'confirmationModalStyle'}>
      <Images.AlertCircleOutline />
      <AgxHeader size={3}>Starting a submission?</AgxHeader>
      <AgxBodyText medium>
        You will not be able to edit the {formName} once you continue.
      </AgxBodyText>
      <AgxBodyText medium>Continue to submission?</AgxBodyText>
      <div className={'confirmationButtonContainerStyle'}>
        <AgxButton
          id="startsubmission"
          large
          primary
          text="Yes, start submission"
          rightIcon={<Images.ArrowForwardOutline />}
          onClick={beginAgencyAgreementSubmission}
          disabled={
            disableButtons ||
            ((availableForms == null || availableForms.length === 0) &&
              currentTab === Tabs.Archive)
          }
        />
        <AgxButton
          id="cancel"
          hollow
          large
          disabled={disableButtons || currentTab === Tabs.Archive}
          text="No, go back"
          rightIcon={<Images.CloseOutline />}
          onClick={() => setShowConfirmationModel(false)}
        />
      </div>
    </AgxColumn>
  );

  return (
    <>
      <FormDetailsHeader
        formName={formName}
        detail={lastUpdatedOn}
        currentTab={currentTab}
        onBack={undefined}
      />
      <AgxColumn centered veryLargeGap extraClasses="resumeFormDetails">
        <AgxHeader size={2} centered>
          {addressLineOne},
          <br />
          {addressLineTwo}
        </AgxHeader>
        <Images.SparkleForm />
        <AgxColumn mediumGap centered>
          <AgxHeader size={3} centered>
            Let's continue from where you left off
          </AgxHeader>
          <AgxBodyText small centered>
            There are more fields to fill
          </AgxBodyText>
        </AgxColumn>
      </AgxColumn>
      <div className="campaignModalButtons">
        <AgxButton
          primary={mobile || isHaveGeneratedDocs}
          hollow={!isHaveGeneratedDocs && !mobile}
          wide={mobile}
          large
          disabled={disableButtons || currentTab === Tabs.Archive}
          text={`Resume ${mobile ? '' : !isHaveGeneratedDocs ? formName : ''}`}
          rightIcon={<Images.ArrowForwardOutline />}
          onClick={resumeAgencyAgreement}
          shrinkOnLargerDevices
        />
        {!inProgressForms.some(
          ipf => ipf.formType === FormType.ListingSubmission
        ) &&
          !!campaign.agencyAgreementSubmission &&
          isHaveGeneratedDocs && (
            <AgxButton
              hollow
              wide={mobile}
              large
              disabled={disableButtons || currentTab === Tabs.Archive}
              text="Start Submission"
              rightIcon={<Images.FolderAddOutline />}
              onClick={() => setShowConfirmationModel(true)}
              shrinkOnLargerDevices
            />
          )}
      </div>

      {showConfirmationModel && (
        <AgxSlideUpModal
          closeButton
          content={confirmationDialogBody}
          desktop={!isMobile}
          onClose={() => setShowConfirmationModel(false)}
        />
      )}
    </>
  );
};

export default ResumeFormDetails;
